.ant-form {
  .ant-form-item-label {
    label {
      color: var(--main-text-color);
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
    }
  }

  .ant-form-item {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
