a {
  text-decoration: none;
  outline: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    transition: all 0.3s ease-in-out;
  }
}

.os-desc {
  font-size: 16px;
  line-height: 22px;
  color: var(--main-text-color);
}
