.ant-picker {
  background: var(--card-bg);
  color: var(--main-text-color);
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  border-color: var(--muted-text-color);
  border-radius: 6px;
}

.ant-picker-suffix,
.ant-picker-clear,
.ant-picker-separator,
.ant-picker-input > input {
  color: var(--main-text-color);
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
}

.ant-picker-clear {
  background: var(--card-bg);
}
