.ant-input {
  background: var(--card-bg);
  color: var(--main-text-color);
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  border-radius: 6px;
  border-color: var(--muted-text-color);
  padding: 10px 16px;
  height: auto;
  box-shadow: none;
  outline: none;

  &:hover,
  &:active {
    border-color: var(--primary-color);
  }
}

.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input:hover {
  background: var(--card-bg);
  border-color: var(--danger-color);
}

.ant-input-sm,
.ant-input-number-sm {
  padding: 6px 16px;
  font-size: 14px;
  line-height: 16px;
}

.ant-input-lg,
.ant-input-number-lg {
  padding: 14px 16px;
  font-size: 18px;
  line-height: 20px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--main-text-color);
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px var(--card-bg);
}

.ant-input-number {
  background: var(--card-bg);
  width: 100%;
  color: var(--main-text-color);
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  border-radius: 6px;
  border-color: var(--muted-text-color);
  padding: 10px 16px;
}

.ant-input-number-input {
  height: auto;
  padding-left: 0;
  padding-right: 0;
}

.ant-input-number-handler-wrap {
  background: var(--card-bg);
  color: var(--main-text-color);
  border-color: var(--muted-text-color);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;

  svg {
    fill: var(--main-text-color);
  }
}

.ant-input-number-handler {
  color: var(--main-text-color);
  border-color: var(--muted-text-color);
}
