.mbMenu {
  display: none;
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;

  .wrapper {
    padding: 12px;
    background: var(--card-bg);
    border-radius: 12px;
    background:
      linear-gradient(var(--card-bg), var(--card-bg)) padding-box,
      linear-gradient(42deg, var(--card-bg) 44%, var(--card-light-colorized))
        border-box;
    border: 1px solid transparent;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 24px;

      li {
        a {
          display: block;
          font-size: 28px;
          line-height: 1;
          color: var(--main-text-color);
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .mbMenu {
    display: block;
  }
}
