.ant-select {
  color: var(--main-text-color);
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: var(--card-bg);
  border-color: var(--muted-text-color);
  border-radius: 6px;
  padding: 10px 16px !important;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
}

.ant-select-sm:not(.ant-select-customize-input) {
  .ant-select-selector {
    padding: 6px 16px !important;
    font-size: 14px;
    line-height: 16px;
  }
}

.ant-select-lg:not(.ant-select-customize-input) {
  .ant-select-selector {
    padding: 5px 16px !important;
    font-size: 18px;
    line-height: 20px;
  }
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  height: auto;
}

.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: auto;
}

.ant-select-dropdown {
  color: var(--main-text-color);
  background-color: var(--card-bg);
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: var(--card-bg);
}

.ant-select-item {
  color: var(--main-text-color);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: var(--primary-color);
  background-color: var(--card-bg);
}
