html,
body {
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: var(--main-text-color);
}

html,
body,
#root {
  min-height: 100%;
}

.app-body,
div[data-layout='app'] {
  width: 100%;
  min-height: 100%;
  background: linear-gradient(
    -45deg,
    #131316 0%,
    #171a22 34%,
    #101318 71%,
    #151516 99%
  );
}
