.ant-btn {
  color: var(--main-text-color);
  border-radius: 6px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  height: auto;
  padding: 10px 16px;
  background-blend-mode: normal;
  transition: background 0.3s ease-in-out;

  &:hover,
  &:active {
    transition: background 0.3s ease-in-out;
  }
}

.ant-btn-sm {
  padding: 6px 16px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
}

.ant-btn-lg {
  padding: 14px 16px;
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
}

.ant-btn-primary {
  background: var(--primary-color);
  border-color: var(--primary-color);

  &:active,
  &:hover {
    background: var(--primary-light-color);
    border-color: var(--primary-light-color);
  }
}

.ant-btn-default {
  background: var(--muted-text-color);
  border-color: var(--muted-text-color);

  &:active,
  &:hover {
    background: var(--muted-text-color);
    border-color: var(--muted-text-color);
    opacity: 0.9;
  }
}

.highlight-accent-button {
  width: 100%;
  padding: 10px 24px;
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  color: #fff;
  border-radius: 10px;
  background: linear-gradient(
    42deg,
    var(--accent-color) 44%,
    var(--primary-color)
  );
  box-shadow: none;
  border: 0;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:active {
    background: linear-gradient(
      42deg,
      var(--accent-color) 14%,
      var(--primary-color)
    );
    transition: all 0.3s ease-in-out;
  }
}
