.footer {
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  .copy {
    color: var(--muted-text-color);
    font-size: 14px;
    line-height: 18px;
  }
}
