.ant-drawer {
  .ant-drawer-mask {
    backdrop-filter: blur(6px);
  }

  .ant-drawer-content {
    background: var(--card-bg);
  }

  .ant-drawer-header {
    border: 0;
  }

  .ant-drawer-title {
    color: var(--main-text-color);
  }

  .anticon {
    vertical-align: -3px;
    color: var(--main-text-color);
  }
}

.ant-drawer-right > .ant-drawer-content-wrapper {
  max-width: 100%;
}
