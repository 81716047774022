.ant-pagination {
  .ant-pagination-item {
    background-color: transparent;
    border: 1px solid var(--card-light-colorized);
    color: var(--main-text-color);
    font-size: 18px;
    line-height: 1;
    font-weight: 400;

    &:hover {
      color: var(--primary-color);
    }
  }

  .ant-pagination-item-active {
    background: transparent;
    border-color: transparent;
    color: var(--primary-color);
    font-weight: 500;
  }

  .ant-pagination-next button,
  .ant-pagination-prev button {
    color: var(--main-text-color);
  }

  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link {
    color: var(--muted-text-color);
    border-color: transparent;
  }

  .ant-pagination-next:hover button,
  .ant-pagination-prev:hover button,
  .ant-pagination-next:focus-visible .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link,
  .ant-pagination-prev:focus-visible .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link {
    color: var(--primary-color);
    border-color: transparent;
  }

  .anticon {
    vertical-align: -2px;
  }
}
