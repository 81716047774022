.sidebar {
  .logo {
    padding: 28px 24px 36px;

    a {
      display: block;

      svg {
        height: 36px;
        width: auto;
      }
    }
  }

  .menu {
    padding: 0 24px;

    > ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        a {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 12px 0;
          text-decoration: none;
          outline: none;
          transition: all 0.3s ease-in-out;

          .name,
          .icon {
            color: var(--main-text-color);
            font-size: 16px;
            line-height: 18px;
            font-weight: 400;
            transition: all 0.3s ease-in-out;
          }

          .name {
          }

          .icon {
            font-size: 20px;
            line-height: 1;
            margin-right: 12px;
            margin-top: -3px;
          }

          &:hover {
            transition: all 0.3s ease-in-out;

            .name,
            .icon {
              color: var(--primary-color);
              transition: all 0.3s ease-in-out;
            }
          }
        }
      }

      li[data-active='true'] a {
        .name,
        .icon {
          color: var(--primary-light-color);
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
}
