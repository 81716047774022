.header {
  position: fixed;
  top: 12px;
  left: 0;
  width: 100%;
  padding-left: 280px;
  z-index: 15;

  .wrapper {
    padding: 12px 24px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: none;
    background: transparent;
    transition: all 0.3s ease-in-out;

    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 24px;

        .backPath {
          margin-right: 12px;

          a {
            display: block;
            color: var(--main-text-color);
            font-size: 32px;
            line-height: 1;
            margin-top: -2px;

            &:hover {
              color: var(--primary-color);
            }
          }
        }

        .pageTitle {
          font-size: 30px;
          line-height: 32px;
          font-weight: 600;
          cursor: default;
          transition: all 0.3s ease-in-out;
        }
      }

      .breadcrumbs {
        ul {
          padding: 0;
          margin: 0;
          list-style: none;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          li {
            position: relative;
            padding-right: 6px;
            margin-right: 8px;

            &:after {
              content: '';
              position: absolute;
              top: 50%;
              left: 100%;
              transform: translateY(-50%);
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background: var(--muted-text-color);
            }

            &:last-child:after {
              display: none;
            }

            a {
              text-decoration: none;

              &:hover {
                color: var(--primary-color);
              }
            }

            span {
              cursor: default;
            }

            a,
            span {
              font-size: 14px;
              line-height: 16px;
              color: var(--muted-text-color);
            }
          }
        }
      }
    }
  }
}

.header[data-headeroffset='true'] .wrapper {
  border-radius: 12px;
  padding: 24px;
  background: var(--card-bg);
  box-shadow: rgba(19, 22, 29, 0.2) 0px 7px 29px 0px;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 1024px) {
  .header {
    padding-left: 0;

    .wrapper {
      padding: 12px 0px;

      .left {
        .title {
          margin-right: 16px;

          .pageTitle {
            font-size: 18px;
            line-height: 22px;
          }
        }

        .breadcrumbs {
          display: none;

          ul {
            li {
              a,
              span {
                font-size: 13px;
                line-height: 15px;
              }
            }
          }
        }
      }
    }
  }

  .header[data-headeroffset='true'] .wrapper {
    padding: 16px;
  }
}

@media (min-width: 1024px) and (max-width: 1240px) {
  .header {
    .wrapper {
      .left {
        .breadcrumbs {
          ul {
            li {
              a,
              span {
                font-size: 13px;
                line-height: 15px;
              }
            }
          }
        }
      }
    }
  }
}
