.pt-table__wrapper {
  overflow-x: auto;
}

.pt-table {
  width: 100%;

  thead {
    tr {
      border-bottom: 2px solid var(--card-light-colorized);

      th {
        padding: 8px 12px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: var(--muted-text-color);
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid var(--card-light-colorized);
      transition: all 0.3s ease-in-out;

      &:hover {
        background: rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease-in-out;
      }

      &:last-child {
        border-bottom: 0;
      }

      td {
        padding: 8px 12px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: var(--main-text-color);

        button {
          padding: 6px;
          margin: 0;
          border: none;
          background: none;
          box-shadow: none;

          svg {
            margin-right: 4px;
          }

          span {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
