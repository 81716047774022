.ant-message-notice-content {
  background: var(--card-bg);
  color: var(--main-text-color);
  border-radius: 6px;
}

.ant-message-notice-content .anticon {
  vertical-align: -2px;
}

.ant-notification-notice {
  background: var(--card-bg);
}

.ant-notification-notice-close-x,
.ant-notification-notice-description,
.ant-notification-notice-message {
  color: var(--main-text-color);
}
