.userMenu {
  padding: 0;
  margin: 0;
  border: 0;
  box-shadow: none;
  background: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  outline: none;

  .icon {
    font-size: 18px;
    margin-right: 10px;
    padding: 6px;
    border-radius: 50%;
    border: 2px solid var(--main-text-color);
  }

  .username {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }
}

.userMenuDrawer {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      border-bottom: 1px solid var(--card-light-colorized);

      &:last-child {
        border-bottom: 0;
      }

      a {
        display: block;
        padding: 12px 0;
        color: var(--main-text-color);

        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .userMenu {
    margin-left: 24px;

    .icon {
      font-size: 15px;
      line-height: 1;
      margin-right: 8px;
      padding: 8px;
      border: 1px solid var(--main-text-color);
    }

    .username {
      display: none;
    }
  }
}
