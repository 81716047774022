.ant-popover,
.ant-popover-message {
  color: var(--main-text-color);
}

.ant-popover-inner-content {
  color: var(--main-text-color);
}

.ant-popover-inner {
  border-radius: 6px;
}

.ant-popover-inner,
.ant-popover-arrow-content:before {
  background-color: var(--card-light-colorized);
}
