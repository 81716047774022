.year-filters {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;

  &__year {
    button {
      padding: 10px 16px;
      font-size: 18px;
      line-height: 1;
      border-radius: 6px;
      margin: 0;
      background: transparent;
      box-shadow: none;
      color: var(--main-text-color);
      border: 1px solid transparent;
      font-weight: 500;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: var(--primary-color);
        transition: all 0.3s ease-in-out;
      }
    }

    button[data-active='true'] {
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
      transition: all 0.3s ease-in-out;
    }
  }
}
