.auth-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 0 15px;
  position: relative;

  .auth-content {
    width: 100%;
    max-width: 540px;
    position: relative;
    z-index: 5;

    .auth-content__wrapper {
      position: relative;
      z-index: 3;

      .acw__logo {
        text-align: center;
        margin-bottom: 24px;

        svg {
          width: 100%;
          max-width: 320px;
          height: auto;
        }
      }
    }
  }
}
