.ant-modal-mask {
  backdrop-filter: blur(6px);
}

.ant-modal,
.ant-modal-close-x {
  color: var(--main-text-color);
}

.ant-modal-content {
  background-color: var(--card-bg);
  border-radius: 6px;
}

.ant-modal-body {
  padding-top: 36px;
}
