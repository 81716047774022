:root {
  --main-text-color: #ffffff;
  --muted-text-color: #888686;
  --accent-color: #8a5be1;
  --danger-color: #b10d06;
  --success-color: #40e0d7;
  --primary-color: #394edd;
  --primary-light-color: #5265e5;

  --layout-bg1: #131316;

  --card-bg: #13161d;
  --card-light-colorized: #282932;
  --card-bg-transparent: rgba(19, 22, 29, 0.3);
}
