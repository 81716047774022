.recharts__label {
  background: linear-gradient(
    -45deg,
    #131316 0%,
    #171a22 34%,
    #101318 71%,
    #151516 99%
  );
  color: var(--main-text-color);
  padding: 12px;
  border-radius: 6px;

  p {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-value {
    font-weight: 600;
    font-size: 16px;
  }
}
